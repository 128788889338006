exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brochure-jp-thank-you-tsx": () => import("./../../../src/pages/brochure-jp-thank-you.tsx" /* webpackChunkName: "component---src-pages-brochure-jp-thank-you-tsx" */),
  "component---src-pages-brochure-jp-tsx": () => import("./../../../src/pages/brochure-jp.tsx" /* webpackChunkName: "component---src-pages-brochure-jp-tsx" */),
  "component---src-pages-contact-jp-thanks-tsx": () => import("./../../../src/pages/contact-jp-thanks.tsx" /* webpackChunkName: "component---src-pages-contact-jp-thanks-tsx" */),
  "component---src-pages-contact-jp-tsx": () => import("./../../../src/pages/contact-jp.tsx" /* webpackChunkName: "component---src-pages-contact-jp-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-tp-freshworks-free-trial-thanks-tsx": () => import("./../../../src/pages/tp/freshworks/free-trial-thanks.tsx" /* webpackChunkName: "component---src-pages-tp-freshworks-free-trial-thanks-tsx" */),
  "component---src-pages-tp-freshworks-free-trial-tsx": () => import("./../../../src/pages/tp/freshworks/free-trial.tsx" /* webpackChunkName: "component---src-pages-tp-freshworks-free-trial-tsx" */),
  "component---src-pages-tp-freshworks-startups-tsx": () => import("./../../../src/pages/tp/freshworks/startups.tsx" /* webpackChunkName: "component---src-pages-tp-freshworks-startups-tsx" */),
  "component---src-templates-news-news-details-template-tsx": () => import("./../../../src/templates/news/news-details.template.tsx" /* webpackChunkName: "component---src-templates-news-news-details-template-tsx" */),
  "component---src-templates-news-news-list-template-tsx": () => import("./../../../src/templates/news/news-list.template.tsx" /* webpackChunkName: "component---src-templates-news-news-list-template-tsx" */),
  "component---src-templates-products-index-tsx": () => import("./../../../src/templates/products/index.tsx" /* webpackChunkName: "component---src-templates-products-index-tsx" */),
  "component---src-templates-products-products-list-template-tsx": () => import("./../../../src/templates/products/products-list.template.tsx" /* webpackChunkName: "component---src-templates-products-products-list-template-tsx" */),
  "component---src-templates-start-guide-index-tsx": () => import("./../../../src/templates/startGuide/index.tsx" /* webpackChunkName: "component---src-templates-start-guide-index-tsx" */)
}

